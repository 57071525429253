import * as React from 'react'
import { graphql } from 'gatsby'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'
import { SliceZone } from '@prismicio/react'

import { Layout } from '../../components/Layout'
import { components } from '../../slices'
import {isRestaurantOpen} from '../../utils/utils'


const Restaurant = ({data}) => {
  console.log('Restaurant data: ', data)
  if (!data) return null

  const topMenu = data.prismicTopMenu || {}
  const footer = data.prismicFooter || {}

  const {id, type, uid} = data.prismicRestaurantDetail
  console.log(`${uid} ${type} ${id}`)
  const slices = data.prismicRestaurantDetail.data
  // console.log('rest detail slices: ', slices)
  const restDetailSlice = slices.body.find(item => item.slice_type === 'restaurant_detail');
  const seo = {
    title: slices.seo_title || restDetailSlice.primary.rest_name,
    description: slices.seo_description || restDetailSlice.primary.description.text.substring(0, 297) + "...",
    keywords: slices.seo_keywords ,
    metaImage: restDetailSlice.primary.logo.url || "/default-rest-logo.png"
  }
  console.log('seo: ', seo)

  console.log('Links to events: ', slices.links_to_events)
  const isOpen = isRestaurantOpen({restItem:data.prismicRestaurantDetail}) 
  const cntx = {links_to_events: slices.links_to_events, links_to_videoblogs: slices.links_to_videoblogs, isOpen}

  
  // console.log('rest detail detail: ', restDetailSlice)
  // const {address, coordinates} = restDetailSlice.primary;
  

  return (
    <Layout topMenu={topMenu.data} footer={footer} seo={seo}>
      <div className="pb-5">
        <SliceZone slices={slices.body} components={components} context={cntx} />
      </div>
    </Layout>
  )
}

export const query = graphql`
  query($id: String!) {
    prismicRestaurantDetail(id: { eq: $id }) {
      _previewable
      id
      uid
      type
      data {
        seo_title
        seo_description
        seo_keywords

        body {
          ...RestaurantDetail
          ...RestaurantDetailMenu
        }
        links_to_events {
          linked_event {
            type
            uid
            url
            document {
              ... on PrismicEvent {
                id
                data {
                  start_time
                  finish_time
                  title {
                    richText
                    text
                  }
                  body {
                    ... on PrismicEventDataBodyEventInfo {
                      id
                      slice_type
                      items {
                        image {
                          url
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        links_to_videoblogs {
          linked_videoblog {
            type
            uid
            url
            document {
              ... on PrismicVbItem {
                id
                data {
                  title {
                    richText
                    text
                  }
                  body {
                    ... on PrismicVbItemDataBodyVideoblogInfo {
                      id
                      slice_type
                      primary {
                        video_url {
                          thumbnail_url
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    prismicTopMenu {
      ...TopMenuFragment
    }
    prismicFooter {
      ...FooterFragment
    }
  }
`

export default withPrismicPreview(Restaurant)